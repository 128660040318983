import Tabs from '@/views/tabs';

let swiper = null;

$(document).on('turbolinks:before-cache', () => {
  if (swiper) {
    swiper.destroy();
    swiper = null;
  }
});

$(document).on('turbolinks:load', async () => {
  if (!document.body.className.includes('v4')) { return; }

  const [{ default: Swiper }, { EffectFade, Pagination }] = await Promise.all([
    import('swiper'),
    import('swiper/modules')
  ]);

  const $article = $('article.v4.mobile_app_tabs');
  const paginationData = $article.find('.swiper').data('pagination');
  const tabs = new Tabs($article.find('.b-tabs'));

  swiper = new Swiper($article.find('.swiper')[0], {
    modules: [EffectFade, Pagination],
    wrapperClass: 'swiper-wrapper',
    slideClass: 'swiper-slide',
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: $article.find('.b-tabs .inner')[0],
      clickable: true,
      bulletActiveClass: 'is-active',
      bulletClass: 'tab',
      renderBullet: (index, className) => (
        `<div class="tab ${paginationData[index][0]} ${className}">` +
          `<span>${paginationData[index][1]}</span></div>`
      )
    },
    on: {
      afterInit: tabs.insertMarker,
      slideChange: tabs.syncMarker
    }
  });
});
